<template>
  <div>
    <basic-users-table
      :canDelete="true"
      :canCreate="true"
    />
  </div>
</template>

<script>
import basicUsersTable from "../../components/basic-user/basicUsersTable.vue";

export default {
  components: {
    basicUsersTable
  },
  data: () => ({})
};
</script>
